import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from "@mui/material";
import { BallTriangle } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CreateConsent, getProfileData, getPreferenceCenterThemeByGroupId, getTemplateDetailByConsentGroupId, generateToken, saveParamsToState, callRedirectAPI } from '../../store/actions/CommonAction';
import base64 from 'base-64';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from 'react-bootstrap/Accordion';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// import { styled } from '@mui/material/styles';
import moment from 'moment';
import queryString from 'query-string';
import '../../style.css/toggle.css';
import '../../style.css/MainComponent.css'; // Adjust the path based on your directory structure

let templateIdArray = [];
let checkedStates = {};


const Main = (props) => {
  const toggleRef = useRef({});
  //alert("test")
  const clientIdcallRedirectAPI = useSelector(state => state.common.clientId)
  const referenceId = useSelector(state => state.common.referenceId)
  const location = useLocation();
  // const encryptedData = location.pathname.substring(1);
  // const clientIDrecieved = location.pathname.substring(2);
  // alert("abcd->"+ encryptedData);
  // alert("abcd->" +clientIDrecieved);
  const [selectAllChecked, setSelectAllChecked] = useState(false);  // State for Select All toggle
  const [individualChecked, setIndividualChecked] = useState({});  // State for individual checkboxes

  // const pathname = decodeURIComponent(location.pathname); // Decode in case of encoded characters
  // const pathSegments = pathname.split('/'); // Split the path into segments

  // // Assuming the first part is empty due to initial slash and the second part is the encrypted data
  // const encryptedData = pathSegments[1];
  // const clientIDrecieved = pathSegments[2];

  const queryParams = new URLSearchParams(location.search);
  const encryptedData = queryParams.get('encryptedData');
  const clientIDrecieved = queryParams.get('clientId');
  const callbackUrl = queryParams.get('callback');

  //alert("encryptedData -> " + encryptedData);
  //alert("clientIDrecieved -> " + clientIDrecieved);
  const [checked, setChecked] = useState(false);

  // Decode base64 and parse the original encryptedData
  let originalEncryptedData = null;
  try {
    const decodedData = atob(encryptedData); // Decode base64 string
    originalEncryptedData = JSON.parse(decodedData); // Parse the JSON string
    console.log("Original Encrypted Data:", originalEncryptedData);
  } catch (error) {
    console.error("Failed to decode or parse encryptedData:", error);
  }

  // const queryParams = new URLSearchParams(location.search);

  const tempDetails = useSelector(state => state.common.tempDetails);
  const preferenceCenterTheme = useSelector(state => state.common.theme);
  const profileData = useSelector(state => state.common.profileData);
  const appName = useSelector(state => state.common.App_Name);
  const Logo = useSelector(state => state.common.logo);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  // Define the default logo image path
  const defaultLogo = '/Jio-Logo-Colour-Reliance-Midnight-512.png';

  // Determine the logo to display
  const logoToDisplay = Logo ? Logo : defaultLogo;


  const getConsentGroupDetailsByGroupId = useSelector(state => state.common.getConsentGroupDetailsByGroupId);
  const consentGroupName = useSelector(state => state.common.consentGroupName);
  const templates = useSelector(state => state.common.templates);


  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [topDescriptionColor, setTopDescriptionColor] = useState('#000');

  const [bottomDescriptionColor, setBottomDescriptionColor] = useState('#000');

  const [buttonBackgroundColor, setButtonBackgroundColor] = useState('#000');
  const [buttonColor, setButtonColor] = useState('#fff');

  const [Panel1BackgroundColor, setPanel1BackgroundColor] = useState('#fff');
  const [Panel1color, setPanel1Color] = useState('#000');

  const [Panel2BackgroundColor, setPanel2BackgroundColor] = useState('#fff');
  const [Panel2color, setPanel2Color] = useState('#000');

  // Access the first template and then its templateDetails to get dataConsumer
  const firstTemplate = Array.isArray(templates) && templates.length > 0 ? templates[0] : null;
  const dataConsumerString = firstTemplate && firstTemplate.templateDetails && firstTemplate.templateDetails.dataConsumer
    ? firstTemplate.templateDetails.dataConsumer.join(', ')
    : 'No data consumer available';

  // const logo = useSelector(state => state.common.logo);
  const consentText = useSelector(state => state.common.consentText);
  const [topDescVal, setTopDescVal] = useState('');
  const [bottomDescVal, setBottomDescVal] = useState('');
  const appNameRedirectApiCall = useSelector(state => state.common.appName);
  const themeRedirectApiCall = useSelector(state => state.common.theme);
  //alert("themeRedirectApiCall->"+ JSON.stringify(themeRedirectApiCall));

  const noticeFileUrl = useSelector(state => state.common.data);
  //let baseVal = `data:application/pdf;base64,${noticeFileUrl}`;


  console.log(' by me-->' + JSON.stringify(noticeFileUrl));



  // console.log('appNameRedirectApiCall-->' + JSON.stringify(appNameRedirectApiCall));
  // console.log('themeRedirectApiCall-->' + JSON.stringify(themeRedirectApiCall));
  // console.log('templates-->' + JSON.stringify(templates));

  const [toggleStates, setToggleStates] = useState(new Array(getConsentGroupDetailsByGroupId.length).fill(false));
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  //const [templateIdArray, setTemplateIdArray] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [blobUrl, setBlobUrl] = useState('');
  // const [checkedStates, setCheckedStates] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  // const encryptedData = useSelector(state => state.common.encryptedData);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const avatarImage = (Logo == undefined || Logo == null) ? accountcircle : Logo;

  // const noticeFileUrl = "/sample.pdf";

  let consentGroupId = '';
  let customerId = '';
  let clientId = '';
  let clientSecret = '';

  const toggleLoader = () => {
    setTimeout(() => setLoader(!loader), 3000);
  }

  // const handleCheckboxChange = (event, value) => {
  //   console.log("value-->", value)
  //   //alert("Alert in handleCheckboxChange" + event.target.checked);
  //   if (event.target.checked) {
  //     console.log('Checkbox is checked! Value:', value);
  //     // Call your desired function here and pass the value
  //     checkOtherEntitiesCheck(value);
  //   } else {
  //     console.log('Checkbox is unchecked! Value:', value);
  //     document.getElementById('selectAllToggle').checked = false;
  //     // Call your desired function here and pass the value
  //     checkOtherEntitiesCheck(value);
  //   }
  // }






  const handleCheckboxChange = (event, value) => {
    console.log("value-->", value);

    if (event.target.checked) {
      console.log('Checkbox is checked! Value:', value);
      // Call your desired function here and pass the value
      checkOtherEntitiesCheck(value);
    } else {
      console.log('Checkbox is unchecked! Value:', value);
      document.getElementById('selectAllToggle').checked = false; // Uncheck "Select All" toggle
      checkOtherEntitiesCheck(value);
    }

    // Check if all toggles are checked
    // const allTogglesChecked = templates.every(item => {
    //     const element = document.getElementById(item.templateId);
    //     console.log('Check if all toggles are checked:', element);
    //     return element && element.checked;

    // });

    let allTogglesChecked = false;
    const allChildCheckboxes = [];

    for (var template of templates) {
      const element = document.getElementById(template.templateId);
      if (element && element.type === 'checkbox') 
        { 
       allChildCheckboxes.push(element);
        }
    }

    if (allChildCheckboxes.every(checkbox => checkbox && checkbox.checked))
      {        
        document.getElementById('selectAllToggle').checked = true; // check "Select All" toggle
      }
      else {        
        document.getElementById('selectAllToggle').checked = false; // uncheck "Select All" toggle
      }
  };










  // const handleCheckboxChange = (event, value) => {
  //   console.log("value-->", value)

  //   if (event.target.checked) {
  //     console.log('Checkbox is checked! Value:', value);
  //     // Call your desired function here and pass the value
  //     checkOtherEntitiesCheck(value);
  //   } else {
  //     console.log('Checkbox is unchecked! Value:', value);
  //     // Call your desired function here and pass the value
  //     checkOtherEntitiesCheck(value);
  //   }
  // }





  // const handleCheckboxChange = (event, templateId) => {
  //   console.log("Template ID-->", templateId);

  //   const isChecked = event.target.checked;

  //   if (isChecked) {
  //     console.log('Checkbox is checked! Value:', templateId);
  //     // Perform your custom function with the checked value
  //     checkOtherEntitiesCheck(templateId, isChecked);
  //   } else {
  //     console.log('Checkbox is unchecked! Value:', templateId);
  //     // Perform your custom function with the unchecked value
  //     checkOtherEntitiesCheck(templateId, isChecked);
  //   }
  // };








  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    // setSelectAllChecked(checked);

    // Create a new object with all checkboxes set to the checked state
    // const updatedChecked = templates.reduce((acc, item) => {
    //   acc[item.templateId] = checked;
    //   return acc;
    // }, {});

    // setIndividualChecked(updatedChecked);

    // Call your existing function to handle the API call or other actions for each templateId
    //templates.forEach(item => handleCheckboxChange({ target: { checked } }, item.templateId));

    for (var item of templates) {
      //alert(item.templateId);

      var element = document.getElementById(item.templateId);
      if (element) {
        element.checked = checked;
        handleCheckboxChange({ target: { checked } }, item.templateId);
      }

    }

  };









  // const handleSelectAll = (event) => {
  //   const checked = event.target.checked;

  //   // Loop through each template item and update its corresponding checkbox in the DOM
  //   templates.forEach((item) => {
  //     const element = document.getElementById(item.templateId);
  //     if (element) {
  //       element.checked = checked; // Directly set checkbox state in DOM
  //       handleCheckboxChange({ target: { checked } }, item.templateId); // Call the existing function to handle any necessary logic
  //     }
  //   });
  // };






  // Memoize the handler to avoid unnecessary re-renders
  const checkOtherEntitiesCheck = (templateId) => {
    // setCheckedStates((prevState) => ({
    //   ...prevState,
    //   [templateId]: !prevState[templateId],
    // }));

    // setTemplateIdArray((prevArray) => {
    //   if (prevArray.includes(templateId)) {
    //     return prevArray.filter((id) => id !== templateId);
    //   } else {
    //     return [...prevArray, templateId];
    //   }
    // });
    // checkedStates[templateId] = !checkedStates[templateId];

    // const isChecked = event.target.checked;
    // setIndividualChecked((prevState) => ({
    //   ...prevState,
    //   [templateId]: isChecked,
    // }));

    // // If any checkbox is unchecked, turn off the master switch
    // if (!isChecked) {
    //   setSelectAllChecked(false);
    // }

    // // Perform the necessary actions (e.g., calling API)
    // console.log(`Checkbox for templateId ${templateId} is ${isChecked ? 'checked' : 'unchecked'}`);




    if (templateIdArray.includes(templateId)) {
      // If the template ID is in the array, remove it
      let updatedTemplateIdArray = templateIdArray.filter(v => v !== templateId);
      //setTemplateIdArray(updatedTemplateIdArray);
      templateIdArray = updatedTemplateIdArray;
    } else {
      // If the template ID is not in the array, add it
      //setTemplateIdArray([...templateIdArray, templateId]);
      templateIdArray.push(templateId);
    }
  };


  // const checkOtherEntitiesCheck = (templateId, isChecked) => {
  //   // Update individual checkbox state
  //   setIndividualChecked((prevState) => ({
  //     ...prevState,
  //     [templateId]: isChecked,
  //   }));

  //   // If any checkbox is unchecked, turn off the master switch
  //   if (!isChecked) {
  //     setSelectAllChecked(false);
  //   }

  //   // If the checkbox is checked, add the templateId to templateIdArray, otherwise remove it
  //   if (isChecked) {
  //     if (!templateIdArray.includes(templateId)) {
  //       templateIdArray.push(templateId);
  //     }
  //   } else {
  //     templateIdArray = templateIdArray.filter((id) => id !== templateId);
  //   }

  //   console.log(`Checkbox for templateId ${templateId} is ${isChecked ? 'checked' : 'unchecked'}`);

  //   if (templateIdArray.includes(templateId)) {
  //     // If the template ID is in the array, remove it
  //     let updatedTemplateIdArray = templateIdArray.filter(v => v !== templateId);
  //     //setTemplateIdArray(updatedTemplateIdArray);
  //     templateIdArray = updatedTemplateIdArray;
  //   } else {
  //     // If the template ID is not in the array, add it
  //     //setTemplateIdArray([...templateIdArray, templateId]);
  //     templateIdArray.push(templateId);
  //   }
  // };




  // const accept = async () => {
  //   try {
  //     document.getElementById("btnfont").style.display = "none";
  //     document.getElementById("consentLoader").style.display = "block";
  //     await dispatch(generateToken());
  //     await dispatch(CreateConsent(templateIdArray, referenceId))
  //     await navigate(`/consent`);
  //   } catch (err) {
  //     console.log('error in screen ' + error + JSON.stringify(error));
  //   }
  // }

  // const accept = async () => {
  //   try {
  //     setIsProcessing(true); // Set loading state to true
  //     // Hide button and show loader
  //     document.getElementById("btnfont").style.display = "none";
  //     document.getElementById("consentLoader").style.display = "block";

  //     // Fetch all active template IDs and add to templateIdArray
  //     const activeTemplateIds = templates
  //       .filter(template => template.templateDetails.mandatory)
  //       .map(template => template.templateId);

  //     //alert("activeTemplateIds->" + JSON.stringify(activeTemplateIds));


  //     if (activeTemplateIds.length > 0) {

  //       for (var templateId of activeTemplateIds) {
  //         if (!templateIdArray.includes(templateId)) {
  //           templateIdArray.push(templateId);
  //         }
  //       }


  //     }

  //     await dispatch(generateToken());
  //     await dispatch(CreateConsent(templateIdArray, referenceId, clientIDrecieved));
  //     await navigate(`/consent`);


  //     // Filter out any IDs that are already in the templateIdArray
  //     // const newTemplateIds = activeTemplateIds.filter(id => !templateIdArray.includes(id));

  //     // Set the updated templateIdArray
  //     // if (newTemplateIds.length > 0) {
  //     //   setTemplateIdArray(prevArray => {
  //     //     const updatedArray = [...prevArray, ...newTemplateIds];

  //     //     // Execute dispatches and navigation here after the state update
  //     //     dispatch(generateToken())
  //     //       .then(() => dispatch(CreateConsent(updatedArray, referenceId, clientIDrecieved)))
  //     //       .then(() => navigate(`/consent`))
  //     //       .catch(err => console.log('Error in dispatch: ' + err + JSON.stringify(err)));

  //     //     return updatedArray; // This returns the new state
  //     //   });
  //     // } else {
  //     //   // If no new IDs, just execute the dispatches
  //     //   await dispatch(generateToken());
  //     //   await dispatch(CreateConsent(templateIdArray, referenceId, clientIDrecieved));
  //     //   await navigate(`/consent`);
  //     // }
  //   } catch (err) {
  //     console.log('Error in screen: ' + err + JSON.stringify(err));
  //     location.href = callbackUrl;
  //   } finally {
  //     setIsProcessing(false); // Reset loading state in case of any errors
  //   }
  // };

  const accept = async () => {
    try {
      setIsProcessing(true); // Set loading state to true

      // Hide button and show loader
      document.getElementById("btnfont").style.display = "none";
      document.getElementById("consentLoader").style.display = "block";

      const activeTemplateIds = templates
        .filter((template) => template.templateDetails.mandatory)
        .map((template) => template.templateId);

      if (activeTemplateIds.length > 0) {
        for (const templateId of activeTemplateIds) {
          if (!templateIdArray.includes(templateId)) {
            templateIdArray.push(templateId);
          }
        }
      }

      //await dispatch(generateToken());
      await dispatch(CreateConsent(templateIdArray, referenceId, clientIDrecieved));

      // Navigate to consent page only if all previous steps are successful
      await navigate(`/consent`);
    } catch (err) {
      console.error('Error in screen:', err);

      // Show a toast notification for the error
      toast.error('Something went wrong. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      // Reset loading state
      setIsProcessing(false);
      // Restore button visibility and hide loader
      document.getElementById("btnfont").style.display = "block";
      document.getElementById("consentLoader").style.display = "none";
    }
  };



  const pdfURL = () => {
    //alert("noticeFileUrl meeeeeee->" + JSON.stringify(noticeFileUrl));
    if (noticeFileUrl) {
      try {
        // Decode the base64 string
        const byteCharacters = atob(noticeFileUrl);

        // Convert the base64 string to a binary string
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Convert binary string to an array buffer
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create a URL for the blob and update state
        const newBlobUrl = URL.createObjectURL(blob);
        setBlobUrl(newBlobUrl); // Update state

        console.log("Blob URL created:", newBlobUrl);

      } catch (error) {
        console.error("Error decoding base64 or creating blob URL:", error);
      }
    } else {
      console.log("Something went wrong: noticeFileUrl is undefined or null");
    }
  };


  const toggleMenu = () => {
    setShowToggle(!showToggle);
  }

  const toggleMenuShare = useCallback(
    (index) => {
      const updatedToggleStates = [...toggleStates];
      updatedToggleStates[index] = !updatedToggleStates[index];
      setToggleStates(updatedToggleStates);
    },
    [toggleStates]
  );

  const getDetailByConsentGroupId = async () => {
    try {
      if (consentGroupId) {
        await dispatch(getTemplateDetailByConsentGroupId(consentGroupId));
        if (getConsentGroupDetailsByGroupId) {
          const mandatoryTemplateIds = getConsentGroupDetailsByGroupId
            .filter(singleTemplate => singleTemplate.templateDetails.mandatory === true)
            .map(singleTemplate => singleTemplate.templateId);
          setTemplateIdArray(mandatoryTemplateIds);
        }
      }
    } catch (error) {
      console.log('error in init screen ' + error + JSON.stringify(error));
      await setError(true);
    }
  }

  const getPreferenceCenterThemeDetailsByGroupId = async () => {
    try {
      if (consentGroupId) {
        await dispatch(getPreferenceCenterThemeByGroupId(consentGroupId));
        if (preferenceCenterTheme.theme !== undefined) {
          const themeObj = JSON.parse(preferenceCenterTheme.theme);
          document.getElementById("descPara").innerHTML = preferenceCenterTheme.topDescription;
        } else {
          document.getElementById("descPara").innerHTML = `While using ${appName}, your activities create data that will be used by ${appName} to offer you better and more customized services. ${appName} uses this data in accordance with your permission (consent), details of which are clearly mentioned below. We do this, so that YOU are always aware of how we use your data, and YOU have total control on managing the usage of your data`;
        }
        if (preferenceCenterTheme.theme !== undefined) {
          const themeObj = JSON.parse(preferenceCenterTheme.theme);
          document.getElementById("descParaBottom").innerHTML = preferenceCenterTheme.bottomDescription;
        } else {
          document.getElementById("descParaBottom").innerHTML = `By tapping/clicking the ‘Confirm Selection’ button below, you are giving your permission (consent) to ${appName} to use your data in accordance with the parameters mentioned above.`;
        }
        applytheme(themeObj);
      }
    } catch (error) {
      console.log('error in init screen ' + error + JSON.stringify(error));
      await setError(true);
    }
  }

  const callAPI = async () => {
    try {
      await dispatch(getProfileData(clientId));
    } catch (error) {
      console.error(error);
    }
  }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
      width: isMobile ? '100%' : '60%', // Set modal width to 50%
      // maxWidth: 'none', // Override the default maxWidth
    },
  }));

  const applytheme = async (theme) => {
    const app = document.getElementById("appColor").style;
    app.backgroundColor = theme.card.backgroundColor;
    app.width = theme.card.width;
    const panelHead1 = document.getElementById("panel-head1").style;
    const panelHead2 = document.getElementById("panel-head2").style;
    panelHead1.backgroundColor = theme.panelHead1.backgroundColor;
    panelHead1.padding = theme.panelHead1.padding;
    panelHead1.color = theme.panelHead1.color;
    panelHead1.fontSize = theme.panelHead1.fontSize;
    panelHead2.backgroundColor = theme.panelHead2.backgroundColor;
    panelHead2.padding = theme.panelHead2.padding;
    panelHead2.color = theme.panelHead2.color;
    panelHead2.fontSize = theme.panelHead2.fontSize;
    const button = document.getElementById("button").style;
    button.backgroundColor = theme.button.backgroundColor;
    button.padding = theme.button.padding;
    button.color = theme.button.color;
    button.fontSize = theme.button.fontSize;
  }

  const [loadingMain, setLoadingMain] = useState(true);
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('application/pdf'); // Default to PDF




  // useEffect(() => {
  //   if (noticeFileUrl) {
  //     let mimeType = 'application/pdf'; // Default MIME type
  //     let extension = '.pdf'; // Default file extension

  //     // Here you would add logic to detect the file type. 
  //     // For example, checking the file name or using a header value.
  //     // For now, let's assume the following logic as an example:

  //     if (noticeFileUrl.startsWith('UEsDB')) {
  //       mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; // Word document
  //       extension = '.docx';
  //     } else if (noticeFileUrl.startsWith('JVBERi0xLjM')) {
  //       mimeType = 'application/pdf'; // PDF file
  //       extension = '.pdf';
  //     } else {
  //       mimeType = 'text/plain'; // Fallback to plain text
  //       extension = '.txt';
  //     }

  //     // Set the MIME type based on detected file type
  //     setFileType(mimeType);

  //     // Decode the Base64 string
  //     const byteCharacters = atob(noticeFileUrl);

  //     // Create a typed array from the binary string
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);

  //     // Create a Blob from the byte array
  //     const blob = new Blob([byteArray], { type: mimeType });

  //     // Create an object URL from the Blob
  //     const fileUrl = URL.createObjectURL(blob);

  //     // Set the generated URL in state
  //     setFileUrl(fileUrl);
  //   }
  // }, [noticeFileUrl]);


  const createConsentAPI = async () => {
    // Prepare request body for createConsent API
    const createConsentBody = {
      referenceId: referenceId,
      templateId: ["7311f867-00a9-4a0a-a67e-1e5fba20c982"]
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`, // Add token from cookies
    };

    try {

      // Call the createConsent API
      const createConsentResponse = await axios.post('http://10.162.6.84:9022/createConsent', createConsentBody, { headers });
      console.log('Create Consent API response:', createConsentResponse.data);

      // Extract callback URL from the createConsent response
      const { callback } = createConsentResponse.data;

      // Navigate to the callback URL
      window.location.href = callback;

    } catch (error) {
      console.error('Error calling APIs:', error);
    }
  };

  // useEffect(() => {
  //   dispatch(generateToken());
  //   let encryptedData = window.location.pathname;
  //   // var encData = encodedParam.split("/");

  //   // let paramObject = JSON.parse(encData);
  //   // clientId = paramObject.clientId;
  //   // clientSecret = paramObject.clientSecret;
  //   // consentGroupId = paramObject.consentGroupId;
  //   // customerId = paramObject.customerId;
  //   // dispatch(saveParamsToState(customerId, clientId, clientSecret, consentGroupId));
  //   toggleLoader();
  //   callAPI();
  //   RedirectAPI(encryptedData);  
  //   createConsentAPI();
  //   getProfileData(clientId);
  //   getDetailByConsentGroupId();
  //   getPreferenceCenterThemeDetailsByGroupId();
  //   setLoadingMain(false);
  // }, []);
  //let PanelStyle = {};
  const applyNewTheme = (themeReceived) => {





    let theme = {};
    console.log("themeReceived:", themeReceived);

    try {
      //console.log("cleanedTheme->", cleanedTheme);
      theme = JSON.parse(JSON.parse(themeReceived));
    } catch (error) {
      console.error('Failed to parse theme:', error.message);
      return; // Exit the function if parsing fails
    }

    console.log('Parsed theme:', theme);

    // Extract and apply styles from the theme object
    const cardStyle = theme.card || {};
    //alert("theme.card-->" + JSON.stringify(theme));
    //alert("cardStyle" + JSON.stringify(cardStyle));
    // const cardElement = document.getElementById("card01").style;
    if (cardStyle) {

      setBackgroundColor(cardStyle.backgroundColor);
      setTopDescriptionColor(cardStyle.color);
      setBottomDescriptionColor(cardStyle.color);
    }


    //button
    const buttonStyle = theme.button || {};

    //const bu = document.getElementById("card01").style;
    if (buttonStyle) {

      setButtonColor(buttonStyle.color);
      setButtonBackgroundColor(buttonStyle.backgroundColor);

    }
    //const panelHeadNew1 = document.querySelector("#panelHeadNew1");
    //const panelHeadNew2 = document.querySelector("#panelHeadNew2");
    // Panels
    const PanelStyle = theme.panel || {};
    if (PanelStyle) {

      // alert("panelHeadNew1->" + panelHeadNew1); // Alert the background color to ensure it's set
      //alert("panelHeadNew1->" + panelHeadNew2);

      // if (panelHeadNew1 !== null) {
      setPanel1BackgroundColor(PanelStyle.backgroundColor);
      setPanel1Color(PanelStyle.color);
      console.log("Panel1BackgroundColor -------->:", Panel1BackgroundColor);
      console.log("Panel1color -------->:", Panel1color);

      //}

      //if (panelHeadNew2 !== null) {
      //panelHeadNew2.style.setProperty('background-color', PanelStyle.backgroundColor, 'important');
      //panelHeadNew2.style.setProperty('color', PanelStyle.color, 'important');
      setPanel2BackgroundColor(PanelStyle.backgroundColor);
      setPanel2Color(PanelStyle.color);
      console.log("Panel2BackgroundColor -------->:", Panel2BackgroundColor);
      console.log("Panel2color -------->:", Panel2color);
      //}

    }

    //panel


    // if (panelHeadNew1 !== null && panelHeadNew2 !== null) {
    //   panelHeadNew1.style.backgroundColor = styleJsonVal.panel.backgroundColor;
    //   panelHeadNew2.style.backgroundColor = styleJsonVal.panel.backgroundColor;
    //   panelHeadNew1.style.color = styleJsonVal.panel.color;
    //   panelHeadNew2.style.color = styleJsonVal.panel.color;
    // }

    const headerText = consentText.header.replace(/<\/?[^>]+(>|$)|&[^;]+;/g, " ");
    const footerText = consentText.footer.replace(/<\/?[^>]+(>|$)|&[^;]+;/g, " ");

    setTopDescVal(headerText);
    setBottomDescVal(footerText);
    // Additional style application logic can be added here
    // if (consentText != null) {
    //   const topDescriptionElement = document.getElementById("topDescription");
    //   if (topDescriptionElement) {
    //     alert("consentText.header->" + consentText.header)
    //     topDescriptionElement.innerHTML = consentText.header;
    //   }
    // }
    setLoadingMain(false); // Hide loader
  }


  useEffect(() => {
    //alert("test2")
    if (originalEncryptedData) {
      setLoadingMain(true); // Show loader
      dispatch(callRedirectAPI(originalEncryptedData, clientIDrecieved, callbackUrl))
        .then(() => {
          setShowModal(true); // Show modal if API call is successful
          //applyNewTheme(themeRedirectApiCall);
          pdfURL();
        })
        .catch((error) => {
          console.error("Error in RedirectAPI:", error);
          location.href = callbackUrl;
        })

    }
  }, [dispatch, originalEncryptedData]);

  useEffect(() => {
    if (themeRedirectApiCall && templates.length > 0) {
      applyNewTheme(themeRedirectApiCall);
    }
  }, [themeRedirectApiCall, templates]);


  // useEffect(() => {

  //   // console.log("Panel1BackgroundColor:", Panel1BackgroundColor);
  //   // console.log("Panel1color:", Panel1color);
  //   // console.log("Panel2BackgroundColor:", Panel2BackgroundColor);
  //   // console.log("Panel2color:", Panel2color);
  //   //PanelStyle = themeRedirectApiCall.panel || {};
  //   if (Panel1BackgroundColor && Panel1color) {
  //     const panelHeadNew1 = document.querySelector("#panelHeadNew1");
  //     if (panelHeadNew1) {
  //       panelHeadNew1.style.backgroundColor = Panel1BackgroundColor;
  //       panelHeadNew1.style.color = Panel1color;
  //     }
  //   }

  //   if (Panel2BackgroundColor && Panel2color) {
  //     const panelHeadNew2 = document.querySelector("#panelHeadNew2");
  //     if (panelHeadNew2) {
  //       panelHeadNew2.style.backgroundColor = Panel2BackgroundColor;
  //       panelHeadNew2.style.color = Panel2color;
  //     }
  //   }
  // }, [Panel1BackgroundColor, Panel1color, Panel2BackgroundColor, Panel2color]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  const handleClose = () => {
    setShowModal(false);
  };

  // Check if any item meets the conditions to be hidden for the first Accordion
  const shouldRenderAccordionItem1 = Array.isArray(templates) && templates !== null && templates.some(item =>
    (!item.templateDetails.shared && item.templateDetails.mandatory) ||
    (!item.templateDetails.shared && !item.templateDetails.mandatory)
  );

  // Check if any item meets the conditions to be hidden for the second Accordion
  const shouldRenderAccordionItem2 = Array.isArray(templates) && templates !== null && templates.some(item =>
    (item.templateDetails.shared && item.templateDetails.mandatory) || (item.templateDetails.shared && !item.templateDetails.mandatory)
  );





  return (
    <>
      {/* Custom Scrollbar CSS */}
      <style>
        {`
          /* For the inset look */
          ::-webkit-scrollbar {
            width: 12px;
          }

          ::-webkit-scrollbar-track {
            background: ${backgroundColor};
            box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
          }

          ::-webkit-scrollbar-thumb {
            background: ${backgroundColor}; /* Example with red color */
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
          }

          ::-webkit-scrollbar-thumb:window-inactive {
            background: ${backgroundColor};
          }

          /* For Firefox */
          * {
            scrollbar-width: thin;
            scrollbar-color: ${backgroundColor};
          }

          /* Adjust scrollbar for the modal specifically */
          .scrollable-modal {
            overflow-y: auto;
            // max-height: 80vh;
            padding-right: 10px;
          }
        `}
      </style>
      {loadingMain && (
        <div id="pre-load">
          <div id="loader" className="loader">
            <div className="loader-container">
              <div className="loader-icon">
                <img
                  src="Img/loader-icon.png"
                  alt='loader'
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {!loadingMain && (
        <BootstrapDialog
          onClose={(event, reason) => {
            if (reason === "backdropClick" || reason === "escapeKeyDown") {
              return;
            }
            setShowModal(false); // Close only if it's not due to backdrop click or escape key
          }}
          open={showModal}
          disableEscapeKeyDown
          // maxWidth={false} // Set this to false to allow custom width
          maxWidth="md"
          // sx={{
          //   '& .MuiDialog-paper': {
          //     width: '100%', // Full screen width for mobile
          //     height: '100%', // Full height for mobile
          //     borderRadius: '0', // Remove border radius for full-screen modal on mobile
          //     margin: 0, // Remove margin for full screen effect
          //     backgroundColor: backgroundColor,
          //     '@media (min-width: 600px)': {
          //       width: '80%', // Tablet devices
          //       height: 'auto',
          //       borderRadius: '16px', // Add radius back for tablets and larger
          //       margin: 'auto',
          //     },
          //     '@media (min-width: 960px)': {
          //       width: '70%', // Laptops and small desktops
          //       height: 'auto',
          //       borderRadius: '16px',
          //       margin: 'auto',
          //     },
          //     '@media (min-width: 1280px)': {
          //       width: '50%', // Larger screens
          //       height: 'auto',
          //       borderRadius: '16px',
          //       margin: 'auto',
          //     },
          //   },
          // }}
          fullScreen={fullScreen}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {/* <CloseIcon /> */}
          </IconButton>
          {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton> */}
          <DialogContent id='card01' className="scrollable-modal" style={{ 'backgroundColor': backgroundColor, padding: isMobile ? '15px' : 'auto', }}>
            <div className='row'>
              <div className='col-md-12'>
                <div className='pop-logo' style={{ display: 'flex' }}>
                  <img
                    src={logoToDisplay}
                    alt="Logo"
                    style={{ height: '50px' }}
                  />
                  <h3 className='pop-title' style={{ fontFamily: 'JioType', fontSize: '25px', marginLeft: '5px', marginTop: '10px' }}>{appNameRedirectApiCall}</h3>
                </div>
                {/* <h3 className='pop-title'>Manage Consent Preferences</h3> */}
              </div>
            </div>
            <div className='row'>
              <div className='col-12 my-2'>
                <div className="">
                  <div className='card-body' style={{ textAlign: 'justify' }}>
                    {topDescVal && <div id="topDescription" style={{ 'color': topDescriptionColor, fontSize: '13px' }}>{topDescVal}</div>}
                  </div>
                </div>
              </div>
              <div className='col-12 my-1' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '34px' }}>
                <span style={{ fontSize: '14px', marginRight: '10px', marginBottom: '5px' }}>Select All</span>
                <label class="switch">
                  <input type="checkbox" id='selectAllToggle' onChange={(event) => handleSelectAll(event)}></input>
                  <span class="slider round"></span>
                </label>
                {/* <span style={{ fontSize: '14px' }}>Select All</span>
                <Switch checked={selectAllChecked} onChange={(event) => handleSelectAll(event)} /> */}
              </div>
              <div className='col-md-12' style={{ marginTop: '0%' }}>
                <Accordion defaultActiveKey="0" className='pop-custom-accordion'>
                  {shouldRenderAccordionItem1 && (
                    <Accordion.Item eventKey="0">
                      <Accordion.Header id="panelHeadNew1" style={{ backgroundColor: Panel1BackgroundColor, color: Panel1color }}>Data used by {appNameRedirectApiCall}</Accordion.Header>
                      <Accordion.Body className='px-10'>
                        {templates && templates.map((item, key) => {
                          if (item.templateDetails.mandatory && !item.templateDetails.shared) {
                            return (
                              <div key={key} className='row align-items-center mb-0'>
                                <div className='col-6 mb-4'>
                                  <h3
                                    style={{
                                      margin: '0',
                                      color: '#000',
                                      fontFamily: 'JioType',
                                      fontSize: '16px',
                                      fontWeight: '900',
                                      lineHeight: '18px'
                                    }}>
                                    {/* <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Template Name</span> */}
                                    {item.nickName}
                                  </h3>
                                </div>
                                <div className='col-6 mb-4 text-end'>
                                  <span className='status active'>Always Active</span>
                                </div>
                                <div className='col-12'>
                                  <div className='pro-box'
                                    style={{
                                      borderWidth: isMobile ? '0' : '1px',
                                      borderRadius: isMobile ? '0' : '6px',
                                      borderBottom: isMobile ? '3px solid #0a2885' : '1px solid #A6CDCD',
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: isMobile ? '10px 0' : '20px',
                                        background: isMobile ? '#FFF' : '#F7F9FD',
                                        borderTopLeftRadius: '6px',
                                        borderTopRightRadius: '6px',
                                        borderBottom: '1px solid #ccc'
                                      }}>
                                      <div className='row'>
                                        <div className='col-4' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Used By</span>
                                            {item.templateDetails.dataConsumer.join(', ')}
                                          </h4>
                                        </div>
                                        <div className='col-4' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared</span>
                                            {item.templateDetails.consentTypes.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.consentTypes.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        <div className='col-4'>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Duration</span>
                                            {item.templateDetails.consentValidity.value} {item.templateDetails.consentValidity.unit}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        padding: isMobile ? '10px 0' : '20px',
                                        background: isMobile ? '#FFF' : '#E3F0FD',
                                        borderBottomLeftRadius: isMobile ? '0' : '6px',
                                        borderBottomRightRadius: isMobile ? '0' : '6px',
                                        borderBottom: 'none'
                                      }}>
                                      <div className='row'>
                                        <div className='col-md-7' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: isMobile ? '0 0 10px' : '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        {/* <div className='col-md-6'>
                                      <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                        <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                        {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                      </h4>
                                    </div> */}
                                        <div className='col-md-5'>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Item</span>
                                            {item.templateDetails.dataTypes.join(', ')}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* Divider Line */}
                                <hr style={{
                                  margin: '12px',
                                  borderTop: '2px solid rgb(118 118 118)',
                                  width: '-webkit-fill-available',
                                  visibility: isMobile ? 'hidden' : ''
                                }} />
                              </div>
                            );
                          } else if (!item.templateDetails.mandatory && !item.templateDetails.shared) {
                            return (
                              <div key={key} className='row align-items-center mb-0'>
                                <div className='col-6 mb-4'>
                                  <h3
                                    style={{
                                      margin: '0',
                                      color: '#000',
                                      fontFamily: 'JioType',
                                      fontSize: '16px',
                                      fontWeight: '900',
                                      lineHeight: '18px'
                                    }}>
                                    {/* <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Template Name</span> */}
                                    {item.nickName}
                                  </h3>
                                </div>
                                <div className='col-6 mb-4 text-end'>
                                  {/* <Switch
                                    key={item.templateId}
                                    checked= {toggleRef.current[item.templateId] ||false}
                                    id={`inputCheck-${item.templateId}`}
                                    onChange={(event) => handleCheckboxChange(event, item.templateId)} // Use onChange
                                  /> */}
                                  <label class="switch">
                                    <input type="checkbox" id={item.templateId} onChange={(event) => handleCheckboxChange(event, item.templateId)}></input>
                                    <span class="slider round"></span>
                                  </label>
                                  {/* <input type="checkbox" onChange={(event) => handleCheckboxChange(event, item.templateId)}></input> */}
                                  {/* <label class="switch">
                                    <input type="checkbox" id="inputCheck" onClick={() => checkOtherEntitiesCheck(item.templateId)} />
                                    <span class="slider"></span>
                                  </label> */}
                                </div>
                                <div className='col-12'>
                                  <div className='pro-box'
                                    style={{
                                      borderWidth: isMobile ? '0' : '1px',
                                      borderRadius: isMobile ? '0' : '6px',
                                      borderBottom: isMobile ? '3px solid #0a2885' : '1px solid #A6CDCD',
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: isMobile ? '10px 0' : '20px',
                                        background: isMobile ? '#FFF' : '#F7F9FD',
                                        borderTopLeftRadius: '6px',
                                        borderTopRightRadius: '6px',
                                        borderBottom: '1px solid #ccc'
                                      }}>
                                      <div className='row'>
                                        <div className='col-4' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Used By</span>
                                            {item.templateDetails.dataConsumer.join(', ')}
                                          </h4>
                                        </div>
                                        <div className='col-4' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared</span>
                                            {item.templateDetails.consentTypes.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.consentTypes.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        <div className='col-4'>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Duration</span>
                                            {item.templateDetails.consentValidity.value} {item.templateDetails.consentValidity.unit}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        padding: isMobile ? '10px 0' : '20px',
                                        background: isMobile ? '#FFF' : '#E3F0FD',
                                        borderBottomLeftRadius: isMobile ? '0' : '6px',
                                        borderBottomRightRadius: isMobile ? '0' : '6px',
                                        borderBottom: 'none'
                                      }}>
                                      <div className='row'>
                                        <div className='col-md-7' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: isMobile ? '0 0 10px' : '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        {/* <div className='col-md-6'>
                                      <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                        <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                        {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                      </h4>
                                    </div> */}
                                        <div className='col-md-5'>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '13px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Item</span>
                                            {item.templateDetails.dataTypes.join(', ')}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* Divider Line */}
                                <hr style={{
                                  margin: '12px',
                                  borderTop: '2px solid rgb(118 118 118)',
                                  width: '-webkit-fill-available',
                                  visibility: isMobile ? 'hidden' : ''
                                }} />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {shouldRenderAccordionItem2 && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header id='panelHeadNew2' style={{ backgroundColor: Panel2BackgroundColor, color: Panel2color }}>Data Shared with other entities</Accordion.Header>
                      <Accordion.Body className='px-10'>
                        {templates && templates.map((item, index) => {
                          if (!item.templateDetails.mandatory && item.templateDetails.shared) {
                            return (
                              <div className='row align-items-center mb-0' key={index}>
                                <div className='col-6' style={{ marginBottom: '0rem' }}>
                                  <h3
                                    style={{
                                      color: '#000',
                                      fontFamily: 'JioType',
                                      fontSize: '16px',
                                      fontWeight: '900',
                                      lineHeight: '18px'
                                    }}>
                                    {/* <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Template Name</span> */}
                                    {item.nickName}
                                  </h3>
                                </div>


                                <div className='col-6 mb-4 text-end'>
                                  {/* <Switch
                                    key={item.templateId}
                                    checked={checkedStates[item.templateId] || false}
                                    id={`inputCheck-${item.templateId}`}
                                    onChange={() => checkOtherEntitiesCheck(item.templateId)} // Use onChange
                                  /> */}
                                  <label class="switch">
                                    <input type="checkbox" id={item.templateId} onChange={(event) => handleCheckboxChange(event, item.templateId)}></input>
                                    <span class="slider round"></span>
                                  </label>
                                  {/* <label class="switch">
                                    <input type="checkbox" id="inputCheck" onClick={() => checkOtherEntitiesCheck(item.templateId)} />
                                    <span class="slider"></span>
                                  </label> */}
                                </div>

                                <div className='col-12'>
                                  <div className='pro-box'
                                    style={{
                                      borderWidth: isMobile ? '0' : '1px',
                                      borderRadius: isMobile ? '0' : '6px',
                                      borderBottom: isMobile ? '3px solid #0a2885' : '1px solid #A6CDCD',
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: isMobile ? '10px 0' : '20px',
                                        background: isMobile ? '#FFF' : '#F7F9FD',
                                        borderTopLeftRadius: '6px',
                                        borderTopRightRadius: '6px',
                                        borderBottom: '1px solid #ccc'
                                      }}>
                                      <div className='row'>
                                        <div className='col-4' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '14px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared With</span>
                                            {item.templateDetails.dataConsumer.join(', ')}
                                          </h4>
                                        </div>
                                        <div className='col-4' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '14px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared</span>
                                            {item.templateDetails.consentTypes.join(', ')}
                                          </h4>
                                        </div>
                                        <div className='col-4'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: isMobile ? '14px' : '16px', fontWeight: isMobile ? '500' : '900', lineHeight: '18px', whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Duration</span>
                                            {item.templateDetails.consentValidity.value} {item.templateDetails.consentValidity.unit}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    {/* Divider Line */}
                                    <hr style={{
                                      margin: 0,
                                      borderTop: '1px solid #ccc',
                                      visibility: isMobile ? 'hidden' : ''
                                    }} />
                                    <div
                                      style={{
                                        padding: isMobile ? '10px 0' : '20px',
                                        background: isMobile ? '#FFF' : '#E3F0FD',
                                        borderBottomLeftRadius: isMobile ? '0' : '6px',
                                        borderBottomRightRadius: isMobile ? '0' : '6px',
                                        borderBottom: 'none'
                                      }}
                                    >
                                      <div className='row'>
                                        <div className='col-md-7' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: isMobile ? '0 0 10px' : '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '14px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>


                                        <div className='col-md-5'>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '14px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Item</span>
                                            {item.templateDetails.dataTypes.join(', ')}
                                          </h4>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* Divider Line */}
                                <hr style={{
                                  margin: '12px',
                                  borderTop: '2px solid rgb(118 118 118)',
                                  width: '-webkit-fill-available',
                                  visibility: isMobile ? 'hidden' : ''
                                }} />
                              </div>
                            );
                          }
                          else if (item.templateDetails.mandatory && item.templateDetails.shared) {
                            return (
                              <div className='row align-items-center mb-0' key={index}>
                                <div className='col-6 mb-4'>
                                  <h3 style={{
                                    margin: '0',
                                    color: '#000',
                                    fontFamily: 'JioType',
                                    fontSize: '16px',
                                    fontWeight: '900',
                                    lineHeight: '18px'
                                  }}>
                                    {/* <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Template Name</span> */}
                                    {item.nickName}
                                  </h3>
                                </div>

                                <div className='col-6 mb-4 text-end'>
                                  <span className='status active'>Always Active</span>
                                </div>

                                <div className='col-12'>
                                  <div className='pro-box'
                                    style={{
                                      borderWidth: isMobile ? '0' : '1px',
                                      borderRadius: isMobile ? '0' : '6px',
                                      borderBottom: isMobile ? '3px solid #0a2885' : '1px solid #A6CDCD',
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: isMobile ? '10px 0' : '20px',
                                        background: isMobile ? '#FFF' : '#F7F9FD',
                                        borderTopLeftRadius: '6px',
                                        borderTopRightRadius: '6px',
                                        borderBottom: '1px solid #ccc'
                                      }}>
                                      <div className='row'>
                                        <div className='col-4' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '14px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared With</span>
                                            {(item.templateDetails.dataConsumer || []).map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.dataConsumer.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        <div className='col-4' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '14px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Shared</span>
                                            {item.templateDetails.consentTypes.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.consentTypes.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>
                                        <div className='col-4'>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '14px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Duration</span>
                                            {item.templateDetails.consentValidity.value} {item.templateDetails.consentValidity.unit}
                                          </h4>
                                        </div>
                                        {/* <div className='col-md-3'>
                                          <h4 style={{ margin: '0', color: '#000', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div> */}
                                      </div>
                                    </div>
                                    {/* Divider Line */}
                                    <hr style={{
                                      margin: 0,
                                      borderTop: '1px solid #ccc',
                                      visibility: isMobile ? 'hidden' : ''
                                    }} />
                                    <div
                                      style={{
                                        padding: isMobile ? '10px 0' : '20px',
                                        background: isMobile ? '#FFF' : '#E3F0FD',
                                        borderBottomLeftRadius: isMobile ? '0' : '6px',
                                        borderBottomRightRadius: isMobile ? '0' : '6px',
                                        borderBottom: 'none'
                                      }}>
                                      <div className='row'>
                                        <div className='col-md-7' style={{ borderRight: isMobile ? '' : '1px solid #ccc', paddingRight: '10px' }}>
                                          <h4
                                            style={{
                                              margin: isMobile ? '0 0 10px' : '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '14px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Purpose</span>
                                            {item.templateDetails.purpose.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.purpose.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>

                                        <div className='col-md-5'>
                                          <h4
                                            style={{
                                              margin: '0',
                                              color: '#000',
                                              fontFamily: 'JioType',
                                              fontSize: isMobile ? '14px' : '16px',
                                              fontWeight: isMobile ? '500' : '900',
                                              lineHeight: '18px',
                                              whiteSpace: 'normal',
                                              wordWrap: 'break-word',
                                              overflowWrap: 'break-word'
                                            }}>
                                            <span style={{ display: 'block', color: '#999', fontFamily: 'JioType', fontSize: '14px', fontWeight: '500', lineHeight: '18px' }}>Data Item</span>
                                            {item.templateDetails.dataTypes.map((ele, key) => (
                                              <React.Fragment key={key}>{ele}{key === item.templateDetails.dataTypes.length - 1 ? '' : ', '}</React.Fragment>
                                            ))}
                                          </h4>
                                        </div>

                                      </div>
                                    </div>

                                  </div>
                                </div>
                                {/* Divider Line */}
                                <hr style={{
                                  margin: '12px',
                                  borderTop: '2px solid rgb(118 118 118)',
                                  width: '-webkit-fill-available',
                                  visibility: isMobile ? 'hidden' : ''
                                }} />
                              </div>

                            );
                          }
                          return null;
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </div>
            </div>
            {/* <div className="row mt-4">
              <div className="col-md-12" style={{ textAlign: 'justify' }}>
                {bottomDescVal && (
                  <div style={{ color: bottomDescriptionColor, fontSize: '12px' }}>
                    {bottomDescVal}
                  </div>
                )}
              </div>
            </div> */}
            {/* <div className='row mt-4'>
              <div className='col-md-12 text-center'>
                <button
                  onClick={accept}
                  type="button"
                  id="button"
                  className="btn"
                  style={{
                    padding: '7px 10px',
                    background: buttonBackgroundColor,
                    // border: 'none',
                    // minWidth: '57%',
                    textAlign: '-webkit-center',
                    fontWeight: '700',
                    fontSize: '14px',
                    color: buttonColor,
                    // borderRadius: '20px',
                    borderColor: '#000'
                  }}
                >
                  {isProcessing ? (
                    <>
                      <font id="btnfont">Processing...</font>
                      <img
                        src="/consentLoader.gif"
                        id="consentLoader"
                        className="img-responsive p-0"
                        style={{ height: '35px', display: 'block' }}
                      />
                    </>
                  ) : (
                    <>
                      <font id="btnfont">Confirm Selection</font>
                      <img
                        src="/consentLoader.gif"
                        id="consentLoader"
                        className="img-responsive p-0"
                        style={{ height: '35px', display: 'none' }}
                      />
                    </>
                  )}
                </button>
              </div>
            </div> */}

          </DialogContent>
          <DialogActions style={{
            background: '#e5e5e5',
            padding: isMobile ? '0px' : '',
            paddingTop: isMobile ? '8px' : '',
            paddingBottom: isMobile ? '8px' : '',

          }}>
            <div className='container-fluid'>
              <div className='row d-flex align-items-center'>
                <div className='col-8' style={{ textAlign: 'justify' }}>
                  {bottomDescVal && (
                    <div style={{
                      color: bottomDescriptionColor,
                      fontSize: '12px',
                      marginLeft: '9px',
                      marginLeft: isMobile ? '' : '9px',
                    }}>
                      {bottomDescVal}
                    </div>
                  )}
                </div>
                <div className='col-4 text-end'>
                  {/* <button 
                  type="button" 
                  className='btn' 
                  style={{ padding: '6px 12px', background: '#0A2885', color: '#FFF', fontSize: '15px', fontWeight: '700' }}
                >I Agree</button> */}
                  <button
                    onClick={accept}
                    type="button"
                    id="button"
                    className="btn"
                    style={{
                      padding: '7px 10px',
                      background: buttonBackgroundColor,
                      // border: 'none',
                      // minWidth: '57%',
                      textAlign: '-webkit-center',
                      fontWeight: '700',
                      fontSize: '14px',
                      color: buttonColor,
                      // borderRadius: '20px',
                      borderColor: '#000',
                      marginRight: '7%'
                    }}
                  >
                    {isProcessing ? (
                      <>
                        <font id="btnfont"></font>
                        <img
                          src="/consentLoader.gif"
                          id="consentLoader"
                          className="img-responsive p-0"
                          style={{ height: '35px', display: 'block' }}
                        />
                      </>
                    ) : (
                      <>
                        <font id="btnfont">I Agree</font>
                        <img
                          src="/consentLoader.gif"
                          id="consentLoader"
                          className="img-responsive p-0"
                          style={{ height: '35px', display: 'none' }}
                        />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </DialogActions>
        </BootstrapDialog>
      )}
      <ToastContainer />
    </>
  );
};

export default Main;