import {
    INIT_CALL, CONSENT_DATA, CONSENT_AUTHORIZED, CONSENT_HISTORY, SAVE_TEMPID,
    SAVE_AUTH_TOKEN,ENCRYPTAPISUCCCESS,DECRYPTAPISUCCESS,CREATCONSENTSUCCESS,
    GET_TEMP_DETAILS,
    GET_PREFERENCE_CENTER_THEME, PROFILE_DATA, APP_LOGO, APP_NAME, GET_CONSENTGROUPDATABY_GROUPID, paramsFromState,paramsFromStateHistory, GET_CONSENT_TEXT, CALL_BACK_URL
} from "../actions/CommonAction";
 
const initialState = {
    consentHandle: '',
    consentData: '',
    ActiveList: '',
    RevokeList: '',
    AllConsent: '',
    profileData: '',
    tempDetails: '',
    clientId: '',
    clientSecret: '',
    consentGroupId: '',
    customerId: '',
    tempId: '',
    mobNumber: '',
    access_token: '',
    theme: '',
    App_Logo: "",
    App_Name: "",
    getConsentGroupDetailsByGroupId: '',
    consentText : "",
    callback: ''
}
 
 
 
const CommonReducer = (state = initialState, actions) => {
 
    switch (actions.type) {
        case SAVE_AUTH_TOKEN:
            return {
                ...state,
                access_token: actions.access_token
            }
        case ENCRYPTAPISUCCCESS:
                return {
                    ...state,
                   clientId: actions.clientId,
                   encryptedData:actions.encryptedData
                }
                
        case DECRYPTAPISUCCESS:
                return {
                    ...state,
                    consentGroupName: actions.consentGroupName,
                    templates: actions.templates,
                    logo:actions.logo,
                    consentText:actions.consentText,
                    referenceId:actions.referenceId,
                    appName:actions.appName,
                    theme:actions.theme,
                    data:actions.data
                }
        case CREATCONSENTSUCCESS:
                 return {
                    ...state,
                    consentId: actions.consentId,
                    timestamp:actions.timestamp,
                    txnid:actions.txnid
                }

                case CALL_BACK_URL:
                 return {
                    ...state,
                    callback:actions.responseFromCreateConsent,
                }

        case INIT_CALL:
            return {
                ...state,
                consentHandle: actions.consentHandle,
            }
      
        case CONSENT_DATA:
            return {
                ...state,
                consentData: actions.data,
            }
        case CONSENT_DATA:
            return {
                ...state,
                consentData: actions.data,
            }
        case CONSENT_AUTHORIZED:
            return {
                ...state,
                consentHandle: '',
            }
        case CONSENT_HISTORY:
            return {
                ...state,
                ActiveList: actions.ActiveList,
                RevokeList: actions.RevokeList,
                AllConsent: actions.AllConsent
            }
 
        case GET_TEMP_DETAILS:
            return {
                ...state,
                tempDetails: actions.data,
                // clientId:actions.clientId,
                // clientSecret:actions.clientSecret,
                templateId: actions.tempId,
                customerId: actions.customerId
            }
 
        case GET_CONSENTGROUPDATABY_GROUPID:
            //console.log("Reducer------>"+JSON.stringify(actions.data));
            return {
                ...state,
                getConsentGroupDetailsByGroupId: actions.templates
            }
 
        case GET_PREFERENCE_CENTER_THEME:
            return {
                ...state,
                theme: actions.data
            }
        case PROFILE_DATA:
            return {
                ...state,
                access_token: actions.access_token,
                profileData: actions.profileData,
                logo: actions.logo
            }
        case APP_NAME:
 
            return {
 
                ...state,
 
                App_Name: actions.App_Name
 
            }
 
        case APP_LOGO:
 
            return {
 
                ...state,
 
                App_Logo: actions.App_Logo
 
            }
 
        case paramsFromState:
 
            return {
                ...state,
                consentGroupId: actions.consentGroupId,
                customerId: actions.customerId,
                clientId: actions.clientId,
                clientSecret: actions.clientSecret,
                callbackURL : actions.callbackURL
            }
 
            case paramsFromStateHistory:
 
            return {
                ...state,
                customerId: actions.customerId,
                clientId: actions.clientId,
                clientSecret: actions.clientSecret
            }
 
        case SAVE_TEMPID:
            //console.log("val in reducer ", actions.templateId , actions.mob , actions.clientId, actions.clientSecret )
            return {
                ...state,
                consentGroupId: actions.consentGroupId,
                mobNumber: actions.mob,
                clientId: actions.clientId,
                clientSecret: actions.clientSecret
            }

            case GET_CONSENT_TEXT:
            return {
                ...state,
                consentText: actions.data
            }
 
        default: return state;
    }
 
}
 
export default CommonReducer;