import { Cookies } from "react-cookie";
// import { JSEncrypt } from "@wuzhibo/jsencrypt";
// import { Buffer } from "buffer";
// import { textConstant } from "./constant";

const encryptMessageWithPubKey = (message) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(window.$rsaPublicKey);
  const encrypted = encrypt.encrypt(message);
  return encrypted;
}

const cookies = new Cookies();

const isUserLoggedIn = () => {
  return !!getLoggedInUserId();
  //return true;
}

const getAssLvl = (assLvl) => {
  var newVal = assLvl.replace(/^\[([\s\S]*)]$/, "$1");
  var newVal1 = parseInt(newVal);
  return newVal1;
};
const decodedHash = (hashCode) => {
  var num1 = Math.floor(hashCode / 100);
  var num2 = hashCode % 100;
  if (num1 > 93) {
    num1 = num1 + 33 + 104;
  } else {
    num1 = num1 + 33;
  }
  if (num2 > 93) {
    num2 = num2 + 33 + 104;
  } else {
    num2 = num2 + 33;
  }
  var res1 = String.fromCharCode(num1);
  var res2 = String.fromCharCode(num2);
  return [res1, res2];
};

const formatFileSize = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes === 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};
function validateTextField(value) {
  var re = new RegExp(/^[a-zA-Z 0-9-.,!@'/]*$/);
  //var re = new RegExp(/^[-_.,& a-zA-Z0-9()]*$/); to be verified by team
  if (value && re.test(value)) {
    return true;
  }
  return false;
}

function validateGroupName(value) {
  var re = new RegExp(/^[-_.& a-zA-Z0-9()]+$/);
  //var re = new RegExp(/^[-_.,& a-zA-Z0-9()]*$/); to be verified by team
  if (value && re.test(value)) {
    return true;
  }
  return false;
}

function validatePincode(value) {
  var re = new RegExp(/^[1-9][0-9]{5}$/);
  return re.test(value);
}

const formatFile = (bytes) => {
  if (bytes <= 1024) {
    bytes = bytes + " KB";
  } else if (bytes > 1024) {
    bytes = (bytes / 1024).toFixed(2) + " MB";
  } else if (bytes === 1) {
    bytes = bytes + "KB";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};
const month = function (dt) {
  const mList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return mList[dt.getMonth()];
};

function txnGenerate(n) {
  var d = new Date().getTime();
  var d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

function getIpAddress() {
  return "192.222.112.122";
}

function getMacId() {
  return "01:23:45:67:89:AB";
}
function setToken(value) {
  cookies.set("token", value, {
    path: '/'
  });
}

//Raviraj - setLoginId
function setLoginId(value) {
  cookies.set("loginId", value, {
    path: '/'
  });
}
function getLoginId() {
  return cookies.get("loginId");
}


//Raviraj - setLoginId
function setAccountId(value) {
  cookies.set("accountId", value, {
    path: '/'
  });
}
function getAccountId() {
  return cookies.get("accountId");
}

//Raviraj - set Account detail

function setAccountData(value){
  cookies.set("accountData", value, {
    path: '/'
  });
}
function getAccountData() {
  return cookies.get("accountData");
}


//Shailendra - set Account detail for login

function setAccountDataLogin(value){
  cookies.set("accountData", value, {
    path: '/'
  });
}
function getAccountDataLogin() {
  return cookies.get("accountData");
}

function setAccountName(value) {
  cookies.set("accountName", value, {
    path: '/'
  });
}
function getAccountName() {
  return cookies.get("accountName");
}

function setRtoken(value) {
  cookies.set("rtoken", value, {
    path: '/'
  });
}
// Function to set the access token in a cookie
function setAccessToken(access_token) {
  cookies.set("access_token", access_token);
}

// Function to get the access token from the cookie
function getAccessToken() {
  return cookies.get("access_token");
}


function setData(key, value) {
  cookies.set(key, value, {
    path: "/",
  });
}


function setTxnIdForOTP(value){
  cookies.set("txnId",value);
}

function getTxnIdForOTP(){
  return cookies.get("txnId");
}
function getTxnIdForMobile(){
  return cookies.get("txnIdMobile");
}
function setTxnIdForMobile(value){
  cookies.set("txnIdMobile",value);
}
function getTxnIdForEmail(){
  return cookies.get("txnIdEmail");
}
function setTxnIdForEmail(value){
  cookies.set("txnIdEmail",value);
}
function getNameForEmail(){
  return cookies.get("email");
}
function setNameForEmail(value){
  cookies.set("email",value);
}
function getNameForMobile(){
  return cookies.get("mobile");
}
function setNameForMobile(value){
  cookies.set("mobile",value);
}

function getFlowName(){
  return cookies.get("flow");
}
function setFlowName(value){
  console.log("Setting flow name to:", value);
  cookies.set("flow",value);
}

function setDataWithExpiry(key, value, expiryDate) {
  cookies.set(key, value, {
    path: "/",
    expires: expiryDate
  });
}

function getData(key) {
  return cookies.get(key);
}
function setSignMethod(value) {
  const eSignEncode = new Buffer(JSON.stringify(value)).toString("base64");
  cookies.set("storeSignState", eSignEncode);
}

function getSignMethod() {
  let methods = []
  try {
    const eSignDecode = cookies.get("storeSignState");
    methods = JSON.parse(Buffer.from(eSignDecode, "base64").toString("ascii"));
  } catch (error) {
    return [];
  }
  return methods;
}

function removeData(key) {
  return cookies.remove(key, {
    path: "/",
  });
}

function getBracketsString(value) {
  if (value !== null) {
    return " (" + value + ")";
  } else {
    return "";
  }
}

function getDecodedToken() {
  var stringToken = getToken();
  if (stringToken != null) {
    var tokenArray = stringToken.split(".");
    const decodedValue = Buffer.from(tokenArray[1], "base64").toString("ascii");
    return JSON.parse(decodedValue);
  }
  return "";
}

/**
 * Clears all cookies from the browser
 */
function clear() {
  // retrieve all cookies
  let Cookies = document.cookie.split(";");
  // set past expiry to all cookies
  for (let i = 0; i < Cookies.length; i++) {
    let cookieName = Cookies[i].split("=")[0].trim();
    if (cookieName) removeData(cookieName);
  }
}

function getCurrentYear() {
  var date = new Date();
  var year = date.getFullYear();
  return year;
}

function totalPageCount(docListLen, postPerPg) {
  var count = Math.ceil(docListLen / postPerPg);
  return count;
}

function sortingData(alphabet) {
  return function (a, b) {
    var index_a = alphabet.indexOf(a.name[0].toLowerCase()),
      index_b = alphabet.indexOf(b.name[0].toLowerCase());
    if (index_a === index_b) {
      // same first character, sort regular
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return index_a - index_b;
    }
  };
}

function sortingPaticipantData(alphabet) {
  return function (a, b) {
    var index_a = alphabet.indexOf(a.participantDetails[0].toLowerCase()),
      index_b = alphabet.indexOf(b.participantDetails[0].toLowerCase());
    if (index_a === index_b) {
      // same first character, sort regular
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return index_a - index_b;
    }
  };
}

function nameSorting(alphabet) {
  return function (a, b) {
    var index_a = alphabet.indexOf(a.name[0].toLowerCase()),
      index_b = alphabet.indexOf(b.name[0].toLowerCase());
    if (index_a === index_b) {
      // same first character, sort regular
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return index_a - index_b;
    }
  };
}

function sortingPaticipantStatus(alphabet) {
  return function (a, b) {
    var index_a = alphabet.indexOf(a.participantDetails[0].toLowerCase()),
      index_b = alphabet.indexOf(b.participantDetails[0].toLowerCase());
    if (index_a === index_b) {
      // same first character, sort regular
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return index_a - index_b;
    }
  };
}

function sortingPaticipantAction(a, b) {
  return a.action.toLowerCase() > b.action.toLowerCase() ? 1 : -1;
}

function sortingPaticipantDataReport(a, b) {
  return a.performedBy.toLowerCase() > b.performedBy.toLowerCase() ? 1 : -1;
}

function sortingDate(rows) {
  const makeDate = (str) => {
    const [_, yyyy, mm, dd, hh, min, ss] = str.match(
      /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
    );
    return new Date(yyyy, mm - 1, dd, hh, min, ss);
  };

  return rows.sort((a, b) => makeDate(a.cDate) - makeDate(b.cDate));
}
function sortingDateReport(rows) {
  const makeDate = (str) => {
    const [_, yyyy, mm, dd, hh, min, ss] = str.match(
      /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
    );
    return new Date(yyyy, mm - 1, dd, hh, min, ss);
  };

  return rows.sort((a, b) => makeDate(a.eventDate) - makeDate(b.eventDate));
}

function sortStatusAsc(arr) {
  return arr.sort((a, b) => Number(a.statusAc) - Number(b.statusAc));
}

function sortStatusDsc(arr) {
  return arr.sort((a, b) => Number(b.statusAc) - Number(a.statusAc));
}

//adding Last signed Time change to the following function and enabling it to handle null fields
const dateFormating = (date) => {
  if (date !== null && date !== undefined) {
    var newTimeChange = date.replace(/:[^:]*$/, "");

    var dateReset = newTimeChange.split(" ");

    for (var i = 0; i < dateReset.length; i++) {
      var reverseDate = dateReset[0].split("-").reverse().join(".");
    }

    var newDate = reverseDate;

    return newDate;
  } else return "";
};

const dateObjFormat = (date) => {
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return dateFormating(
    date.getFullYear() +
    "-" +
    (mm > 9 ? "" : "0") +
    mm +
    "-" +
    (dd > 9 ? "" : "0") +
    dd +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds()
  );
};

//adding Last signed Time change to the following function and enabling it to handle null fields
const timeFormating = (date) => {
  //let date = "2023-02-21 14:52:54 IST"
  if (date !== null && date !== undefined) {
    var newTimeChange = date.replace(/:[^:]*$/, "");

    var dateReset = newTimeChange.split(" ");

    // for (var i = 0; i < dateReset.length; i++) {
    var time = dateReset[1] + " " + textConstant.dateTimeZone;
    // }
    if (time === undefined) {
      time = "";
    }

    return time;
  } else return "";
};

const timeInHumanFormat = (timeStamp) => {
  if (timeStamp) {
    const newTimeChange = timeStamp.replace(/:[^:]*$/, "");
    const [date, time] = newTimeChange.split(" ");
    const [month, day, year] = date.split("/");
    const newDate = [day, month, year].join(".");
    if (time === undefined) {
      time = "";
    }
    let [hours, minutes] = time.split(":");
    if (hours > 12) {
      // hours = hours - 12;
      // hours = hours < 9 ? `0${hours}` : hours;
      return `${newDate} ${hours}:${minutes}` + " " + textConstant.dateTimeZone;
    }

    return `${newDate} ${hours}:${minutes}` + " " + textConstant.dateTimeZone;
  }
};

const timestampFormating = (date) => {
  //let date = "14.03.2023 15:59" and 2023-03-12 12:59:34
  let pattern = /([0-9]+(\-|.[0-9]+)+) (0?[0-9]|1[0-9]|2[0-3]):[0-9]+:(0?[0-9]|[1-5][0-9])/g;
  var newTimeChange = date;
  if (date.match(pattern)) {
    newTimeChange = date.toString().replace(/:[^:]*$/, "");
  }
  //var newTimeChange = date.toString().replace(/:[^:]*$/, "");
  var dateReset = newTimeChange.split(" ");

  for (var i = 0; i < dateReset.length; i++) {
    var reverseDate = dateReset[0].split("-").reverse().join(".");

    var time = dateReset[1];
  }
  if (time === undefined) {
    time = "";
  }
  var newDate = reverseDate + " " + time + " " + textConstant.dateTimeZone;

  return newDate;
};

const dateInHumanFormat = (timestamp) => {
  const formattedTimestamp =
    typeof timestamp === "string" ? timestamp.replace(/IST /, "") : timestamp;
  const date = new Date(formattedTimestamp);
  const dateInLocaleString = date.toLocaleString("en-GB");
  const dateAndTime = dateInLocaleString.split(", ");
  const time = timeInHumanFormat(dateAndTime[1]);
  return `${dateAndTime[0]} ${time}`;
};

//get logged in user id
const getLoggedInUserId = () => {
  var decodedValue = getDecodedToken();
  return decodedValue.uid;
};

const getParentOrgHierarchy = (data) => {
  //alert("data in getParentOrgHierarchy>util.js");
  var grpName = [];
  for (let i = 0; i < 6; i++) {
    if (data["account" + i + "Name"] != undefined) {
      grpName.splice(0, 0, {
        name: data["account" + i + "Name"],
        order: i,
        accountId: data["account" + i + "Id"],
      });
    } else {
      return grpName;
    }
  }
  return grpName;
};

const getAccountsInside = (account, child) => {
  if (child==null || child.length === 0) {
    return account;
  } else {
    for (let i = 0; i < child.length; i++) {
      if (
        !account.some((element) => {
          return child[i].account0Id === element;
        })
      ) {
        account.push(child[i].account0Id);
      }
      getAccountsInside(account, child[i].children);
    }
  }
  return account;
};

const getAllAccountsUnder = (data) => {
 // alert("data : "+JSON.stringify(data));
  let accounts = getAccountsInside([data.accountId], data.children);
  let grpName = accounts[0];
  for (let i = 1; i < accounts.length; i++) {
    grpName = grpName + "," + accounts[i];
  }
  return grpName;
};

const uListGrpDetails = (data) => {
  var grpName = "";
  for (let i = 0; i < 6; i++) {
    if (data["account" + (i + 1) + "Name"] !== undefined && data["account" + (i + 1) + "Name"] !== null) {
      grpName = "     " + "| " + data["account" + i + "Name"] + grpName;
    } else {
      if (data["account" + i + "Name"])
        grpName = "    " + data["account" + i + "Name"] + grpName;
    }
  }
  return grpName;
};
const mobileIdentifierCount = (data) => {
  let counter = 0;

  for (let i = 0; i < data.length; i++) {
    if (data[i].idenType === 3) counter++;
  }

  counter = counter - 1;
  let idn = 1;
  for (let i = 0; i < data.length; i++) {
    if (data[i].idenType === 3 && idn <= 1) {
      idn++;
      if (counter === 0) {
        counter = data[i].identifier;
      } else {
        counter = data[i].identifier + "(+" + counter + ")";
      }
    }
  }
  if (counter === -1) {
    return (counter = "");
  } else {
    return counter;
  }
};
const getName = (name, datatext) => {
  let str = "";
  let string = "";
  if (name != null) {
    if (datatext === "mob") {
      if (name.length > 18) {
        str = name.slice(0, 18);
        string = str + "...";
        return string;
      } else {
        return name;
      }
    } else if (datatext === "mobinc") {
      if (name.length > 16) {
        str = name.slice(0, 16);
        string = str + "...";
        return string;
      } else {
        return name;
      }
    } else if (datatext === "desk") {
      if (name.length > 19) {
        str = name.slice(0, 19);
        string = str + "...";
        return string;
      } else {
        return name;
      }
    } else if (datatext === "deskinc") {
      if (name.length > 18) {
        str = name.slice(0, 18);
        string = str + "...";
        return string;
      } else {
        return name;
      }
    }
  }
};
const emailIdentifierCount = (data, text) => {
  let counter = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].idenType === 2) counter++;
  }

  counter = counter - 1;
  let idn = 1;
  for (let i = 0; i < data.length; i++) {
    if (data[i].idenType === 2 && idn <= 1) {
      idn++;
      if (counter === 0) {
        if (text === "mob") {
          counter = getName(data[i].identifier, "mob");
        } else {
          counter = getName(data[i].identifier, "desk");
        }
      } else {
        if (text === "mob") {
          counter =
            getName(data[i].identifier, "mobinc") + "(+" + counter + ")";
        } else {
          counter =
            getName(data[i].identifier, "deskinc") + "(+" + counter + ")";
        }
      }
    }
  }
  if (counter === -1) {
    return (counter = "");
  } else {
    return counter;
  }
};

//validate comments input field.
const validateCommmets = (value) => {
  value = value.replace(/[\#\:\?\_\~\*\+\=\%\\\|\`\{\}\$\&\>\<\^\[\]\;]+/, "");
  return value.replace(/\.\./, "");
  // return value.replace(/[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/, "");
};

const validateAddress = (value) => {
  value = value.replace(/[\#\:\?\_\~\*\+\=\%\\\|\`\{\}\$\&\>\<\^\[\]\;]+/, "");
  return value.replace(/\.\./, "");
};

const validateInput = (text) => {
  let replaced = text.replace(/[\#\:\?\_\~\*\+\=\%\\\|\`\{\}\$\&\>\<\^\[\]\;]+/, "");
  return replaced.length === text.length;
}

const getAssLvlFromIdenType = (idenType) => {
  //If identifier type is 1 email and 2 is mobile.
  if (idenType === 1) {
    return 2;
  } else if (idenType === 2) {
    return 3;
  }
};

const setIndividualRolCookie = (aid) => {
  if (aid !== undefined) {
    var obj = cookies.get(aid);
    if (obj === undefined) {
      let objN = { IA: aid };
      let myJSON = JSON.stringify(objN);
      cookies.set(aid, myJSON, { path: "/" });
    }
  }
};

const setGrpRolCookie = (verf, rl) => {
  let ia = "";
  if (verf === "invite") {
    if (rl !== undefined) {
      if (rl === "Group User") {
        ia = getDecodedToken().ac;
      } else {
        ia = getDecodedToken().ac;
      }
    } else {
      ia = getDecodedToken().idenid;
    }
  } else {
    ia = getDecodedToken().aid;
  }

  if (ia !== undefined) {
    var obj = cookies.get(ia);
    if (obj === undefined) {
      //IA= Invited Account
      var objN = { IA: ia };
      var myJSON = JSON.stringify(objN);
      cookies.set(ia, myJSON, { path: "/" });
    }
  }
};

const getGrpRolCookie = (id) => {
  let currentIden = getDecodedToken().aid;
  var obj = cookies.get(currentIden);
  if (obj !== undefined) {
    var invitedAc = obj.IA;
    if (invitedAc === currentIden) {
      return true;
    }
  } else {
    return false;
  }
};

const removeGrpRolCookie = () => {
  let currentIden = getDecodedToken().aid;
  cookies.remove(currentIden, { path: "/" });
};

const getCurrentDate = () => {
  let newDate = new Date();
  let day = String(newDate.getDate()).padStart(2, "0");
  let month = String(newDate.getMonth() + 1).padStart(2, "0");
  let year = newDate.getFullYear();

  return `${day}.${month}.${year}`;
};

// // return date format is DD.MM.YYYY hh:mm IST
const formatDate = (date) => {
  //let date = "26.02.2025 09:23 IST";
  // if date format is not valid then it is return as same

  if (new Date(date) == "Invalid Date") {
    return date;
  }

  date = new Date(date);
  return (
    [
      String(date.getDate()).padStart(2, "0"),
      String(date.getMonth() + 1).padStart(2, "0"),
      date.getFullYear(),
    ].join('.') + ' ' +
    [
      String(date.getHours()).padStart(2, "0"),
      String(date.getMinutes()).padStart(2, "0")
    ].join(':') + " " + textConstant.dateTimeZone
  );
}

// Accept Date format DD.MM.YYYY HH:mm
const isDateBeforeToday = (date) => {
  let splitDate = date.split(/[.\s:]/);
  let current_date = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
  return new Date(current_date) < new Date();
}

/**
 * When status 401 UNAUTHORIZED is received from an API call,
 * we will return the user to login page with the last accessed path appened to the url as redirect url.
 * The redirect URL is base64 encoded.
 * URL example - /login?redirect={encode_url}
 * This will also clear all the cookies
 */
const handleUnauthorizedRedirect = () => {
  const location = window.location;
  const url = location.href.split(location.origin)[1];
  const encodedURL = new Buffer(JSON.stringify(url)).toString('base64');
  clear();
  window.location.href = `/login?redirect=${encodedURL}`;
};

const highestRepeatedNumber = (arr) => {
  const count = {};
  let maxCount = 0;
  let maxNum = null;
  for (let num of arr) {
    if (count[num]) {
      count[num]++;
    } else {
      count[num] = 1;
    }
    if (count[num] > maxCount) {
      maxCount = count[num];
      maxNum = num;
    }
  }
  return maxNum;
}

const stringEncoder = (str) => {
  try {
    return Buffer.from(str).toString("base64")
  } catch (error) {
    return undefined;
  }
}

const stringDecoder = (buff) => {
  try {
    return Buffer.from(buff, "base64").toString();
  } catch (error) {
    return undefined;
  }
}

const getChunkSize = (fileSize) => {
  let chunkSize = 500000;
  if (fileSize < 307200)
    chunkSize = window.$CHUNK_SIZE_50K;
  else if (fileSize > 307200 && fileSize <= 1000000)
    chunkSize = window.$CHUNK_SIZE_200K;
  else if (fileSize > 1000000 && fileSize <= 2000000)
    chunkSize = window.$CHUNK_SIZE_200K;
  else if (fileSize > 2000000 && fileSize <= 5000000)
    chunkSize = window.$CHUNK_SIZE_500K;
  else if (fileSize > 5000000 && fileSize <= 10000000)
    chunkSize = window.$CHUNK_SIZE_1M;
  else if (fileSize > 10000000 && fileSize <= 15000000)
    chunkSize = window.$CHUNK_SIZE_2M;
  else if (fileSize > 15000000 && fileSize <= 19000000)
    chunkSize = window.$CHUNK_SIZE_3M;
  else if (fileSize > 19000000 && fileSize <= 25000000)
    chunkSize = window.$CHUNK_SIZE_4M;
  else if (fileSize > 25000000 && fileSize <= 30000000)
    chunkSize = window.$CHUNK_SIZE_5M;
  else if (fileSize > 30000000)
    chunkSize = window.$CHUNK_SIZE_6M;

  if (!chunkSize)
    chunkSize = 500000;

  return chunkSize;
}

const humanReadableFileSize = (bytes) => {
  if (bytes === 0) {
    return "0.00 B";
  }

  let e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, e)).toFixed(2) +
    ' ' + ' KMGTP'.charAt(e) + 'B';
}

export {
  getCurrentYear,
  decodedHash,
  formatFileSize,
  month,
  txnGenerate,
  getIpAddress,
  getMacId,

  formatFile,
  setData,
  getData,
  clear,
  getDecodedToken,
  sortingData,
  removeData,
  getBracketsString,
  getAssLvl,
  setToken,
  setRtoken,

  totalPageCount,
  dateFormating,
  timeFormating,
  sortingDate,
  sortStatusAsc,
  sortStatusDsc,
  timestampFormating,
  timeInHumanFormat,
  dateInHumanFormat,
  getLoggedInUserId,
  dateObjFormat,
  validateTextField,
  validateGroupName,
  sortingPaticipantData,
  sortingPaticipantStatus,
  uListGrpDetails,
  getParentOrgHierarchy,
  getAllAccountsUnder,
  mobileIdentifierCount,
  emailIdentifierCount,
  validateCommmets,
  validateAddress,
  nameSorting,
  getAssLvlFromIdenType,
  validatePincode,
  setGrpRolCookie,
  setIndividualRolCookie,
  getGrpRolCookie,
  removeGrpRolCookie,
  sortingPaticipantDataReport,
  sortingDateReport,
  sortingPaticipantAction,
  setSignMethod,
  getSignMethod,
  getCurrentDate,
  encryptMessageWithPubKey,
  handleUnauthorizedRedirect,
  isUserLoggedIn,
  formatDate,
  isDateBeforeToday,
  highestRepeatedNumber,
  stringEncoder,
  stringDecoder,
  validateInput,
  setDataWithExpiry,
  getChunkSize,
  humanReadableFileSize,
  getTxnIdForOTP,
  setTxnIdForOTP,
  setTxnIdForMobile,
  getTxnIdForMobile,
  setTxnIdForEmail,
  getTxnIdForEmail,
  getNameForEmail,
  setNameForEmail,
  getNameForMobile,
  setNameForMobile,
  setLoginId,
  getLoginId,
  setAccountId,
  getAccountId,
  setAccountName,
  getAccountName,
  setAccountData,
  getAccountData,
  setAccountDataLogin,
  getAccountDataLogin,
  setFlowName,
  getFlowName,
  getAccessToken,
  setAccessToken
  
};
