import './App.scss';
import { store,persistor } from './configureStore';
import Nav from './component/Nav';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from "react-router-dom";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {
  return ( 
    <Provider store={store} >
    <PersistGate  persistor={persistor}>
          <BrowserRouter>
            <Nav/> 
          </BrowserRouter>


       </PersistGate>
    </Provider>
  );
}

export default App;