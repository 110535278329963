

///for DEV
// const BASE_URL = `http://10.162.0.67:9008/api/v2`  // web portal http://10.162.0.65:9008

// const BASE_URL = `http://49.40.21.113/api/v2`  // web portal http://10.162.0.65:9008
///dev
// const BASE = `http://10.135.25.80:30017/v1/consent`
//idc
// const BASE = `http://10.169.165.146:9017/v1/consent`
//non prod
// const BASE = `http://10.173.184.4:9017/v1/consent`
//prod
// const BASE = `http://10.168.88.8:9017/v1/consent`

// const BASE_URL = `http://10.157.236.21:80/api/v2`  // web portal http://10.162.0.65:9008


// const Init = `${BASE_URL}/initConsent`
// const ConsentdataByconsentHandle = `${BASE_URL}/consent_request/`
// const AuthorizeConsent = `${BASE_URL}/authorizeConsent`
// const ConsentDataBycustomerId = `${BASE_URL}/getConsentData`
// // const ConsentDataBycustomerId = `http://49.40.21.113:80/api/v2/getConsentData`
// const UpdateStatus = `${BASE_URL}/updateConsentStatus`

const BASE = process.env.REACT_APP_BASE_URL

const REDIRECT_V1_5 = process.env.REACT_APP_REDIRECT_URL

const TOKEN = process.env.REACT_APP_TOKEN


// const CreateConsent = `${BASE}/createMultiConsent`
const GET_PROFILE_DATA = `${BASE}/getClientInfo`
const GetTempDetailbyId = `${BASE}/getTemplateDetails`
const ConsentBycustomerId = `${BASE}/getConsent`
const GetConsentDetailConsentId = `${BASE}/getConsent`
const GetRevokedConsentDetailConsentId = `${BASE}/getInactiveConsent`
const GetMultipleTemplatesByTemplateIds = `${BASE}/GetMultipleTemplatesByTemplateIds/`
const getPreferenceCenterThemeByClient = `${BASE}/preferenceCenter/`
const GetConsentDetailsByCustomerId = `${BASE}/getConsentsForCustId`
const REVOKE_CONSENT = `${BASE}/revokeConsent`
const getPreferenceCenterThemeByGroupId = `${BASE}/consentPopup`
const getPreferenceCenterDescriptionByGroupId = `${BASE}/consentGroup`
const GET_CONSENTGROUPID = `${BASE}/consentGroup`

const GET_TEMPLATE_BY_CONSENT_GROUPID = `${BASE}/templateByConsentGroupId`


const CLIENT_ID = `41ac3979-b210-4560-99fc-9a4aa0fe6d01`
const CLIENT_SECRET_VALUE = `K~X8Q~mJnW_IwmK1PMcd7bj3U0l3oEVgCGt2acI3`
const SUBSCRIPTION_KEY = `ff526bebd42144959a1878c90b3e3f1b`
// const CLIENT_SECRET_ID = `cccab8c0-52d5-4641-9608-1da3cb486de9`
// const ENCRYPTAPI = 'http://10.162.6.84:9022/redirect/v1.5/encrypt'
// const DECRYPTAPI = ' http://10.162.6.84:9022/redirect/v1.5/getConsentPopupData/'
// const CREATCONSENT = 'http://10.162.6.84:9022/redirect/v1.5/createConsent'
// const CreateConsent ='http://10.162.6.84:9022/redirect/v1.5/createConsent'

//const ENCRYPTAPI = 'http://10.162.6.84:9022/redirect/v1.5/encrypt' ST
const ENCRYPTAPI = `${REDIRECT_V1_5}/encrypt`
//const DECRYPTAPI = 'http://10.162.6.84:9022/redirect/v1.5/getConsentPopupData/' ST
const DECRYPTAPI = `${REDIRECT_V1_5}/getConsentPopupData/`

const CREATCONSENT = 'http://10.162.6.84:9022/redirect/v1.5/createConsent'

//const CreateConsent ='https://gw.consent.jiolabs.com/redirect/v1.5/createConsent'

const CreateConsent =`${REDIRECT_V1_5}/createConsent`

// const CreateConsent =`${REDIRECT_V1_5}/createConsent`


export default {
    // Init,
    // ConsentdataByconsentHandle,
    // AuthorizeConsent,
    // ConsentDataBycustomerId,
    // UpdateStatus,
    ENCRYPTAPI,
    DECRYPTAPI,
    CREATCONSENT,
    CreateConsent,
    GetTempDetailbyId,
    ConsentBycustomerId,
    GetConsentDetailConsentId ,
    GetRevokedConsentDetailConsentId,
    CLIENT_ID,CLIENT_SECRET_VALUE,SUBSCRIPTION_KEY,
    TOKEN,
    GetMultipleTemplatesByTemplateIds,
    GetConsentDetailsByCustomerId,
    REVOKE_CONSENT,
    getPreferenceCenterThemeByClient,
    getPreferenceCenterThemeByGroupId,
    getPreferenceCenterDescriptionByGroupId,
    GET_CONSENTGROUPID,
    GET_TEMPLATE_BY_CONSENT_GROUPID,
    GET_PROFILE_DATA
}
    
