import React,{useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {
  Link
  } from 'react-router-dom'
  import { Modal } from "react-bootstrap";

  import { useDispatch, useSelector} from 'react-redux';
  import {CheckCircleTwoTone } from '@ant-design/icons';


const  ConsentSuccess = (props)=> {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const callback = useSelector(state => state.common.callback)
    //console.log("callback->" + JSON.stringify(callback));

  // const click = () =>{
  //   // window.open(`http://localhost:3000/onb3`, '_blank').focus();
  //   // window.close()
  //   // navigate('/onb3')
  //   alert("callbackURL" + callback);
  //   location.href = callback;
  // }

  const click = () => {
    const callbackURL = callback.callback; // Extract the callback URL
    //alert("callbackURL: " + callbackURL); // Alert the extracted URL
    //location.href = callbackURL+"/"+callback.txnid; // Redirect to the callback URL
    location.href = callbackURL+"?code="+callback.txnid;
}


  useEffect(() => {
   setTimeout(click, 5000);
   
  }, []);

  return (
    <div className="App App-header ">

          <Modal.Header >
            <div className="d-flex align-items-center">
                  <div className="row text-center">
                    <img  src="/jioLogo.PNG"  className="img-responsive p-2 align-self-center" style={{width: '70px',height: '70px'}} />
                  </div>
                  <Modal.Title className="pr-3 pl-3" style={{color:'white'}}>Consent Manager</Modal.Title>
            </div>
                
          </Modal.Header>

            <Modal.Body  >
              <div className="p-3 m-3" style={{marginVertical:50}}>
              <CheckCircleTwoTone className="m-2"  style={{fontSize:'50px',color: 'black'}} />
                  <h1  style={{fontSize:'20px'}}>Congratulations! !!</h1>

                  <h1  style={{fontSize:'14px'}}>Your data use consent has been successfully captured.</h1>

                  {/* <h1  style={{fontSize:'14px'}}>Transferring you back to JioSaavn !!</h1> */}
              </div>
            </Modal.Body >

    </div>
  );
}

export default ConsentSuccess;
