import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import CommonReducer from './store/reducers/CommonReducer';
import ReduxThunk from 'redux-thunk';

// import rootReducer from './reducers'
 
const persistConfig = {
  key: 'consent_web',
  storage,
}

const rootReducer = combineReducers({
  common: CommonReducer,
});
 
const persistedReducer = persistReducer(persistConfig, rootReducer)
 
// export default () => {
//   let store = createStore(persistedReducer)
//   let persistor = persistStore(store)
//   return { store, persistor }
// }

export const store = createStore(persistedReducer,applyMiddleware(ReduxThunk))
export const persistor = persistStore(store)
