import React from "react";
import { BrowserRouter as Router, Route,Routes } from "react-router-dom";

import Main from "../screen/consentflow/main";
import ConsentSuccess from "../screen/consentflow/consentsuccess";
import ConsentHistory from "../screen/consentflow/consenthistory";

import Preonboarding from "../screen/consentflow/Preonboarding";
import Onboard1 from "../screen/onboarding/onboard1";
import Onboard2 from "../screen/onboarding/onboard2";
import Onboard3 from "../screen/onboarding/onboard3";
import Onboard4 from "../screen/onboarding/onboard4";
import Onboard5 from "../screen/onboarding/onboard5";
import Onboard6 from "../screen/onboarding/onboard6";
import Onboard7 from "../screen/onboarding/onboard7";
import Onboard from "../screen/onboarding/onboard";



function Nav() {
    return (
    <Routes>

            {/* <Route  path='/' element={<Onboard/>} /> */}
            {/* <Route  path='/onb' element={<Onboard/>} /> */}


            {/* CONSENT WEB PAGES FLOW */}
              {/* <Route  path='/:data/:data' element={<Main/>} /> */}
              {/* <Route path='/:encryptedData/:clientId/:callback' element={<Main />} /> */}
              <Route path='/' element={<Main />} />
              <Route  path='/consent' element={<ConsentSuccess/>} />
              {/* <Route  path='/' element={<Preonboarding/>} /> */}
              {/* <Route  path='/consenthistory/:id' element={<ConsentHistory/>} /> */}

            {/* SIGNUP FLOW */}
              {/* <Route  path='/onb1' element={<Onboard1/>} />
              <Route  path='/onb2' element={<Onboard2/>} />
              

              <Route  path='/onb3' element={<Onboard3/>} />
              <Route  path='/onb4' element={<Onboard4/>} />
              <Route  path='/onb5' element={<Onboard5/>} />
              <Route  path='/onb6' element={<Onboard6/>} />
              <Route  path='/onb7' element={<Onboard7/>} /> */}


    </Routes>
    );
  }
  
  export default Nav;